import React, { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min';
import { User } from '../../../storage/store';
import { UserService } from '../../../services/UserService';
import TimeLineView from '../TimeLineView';
import GC_gate from './GC_gate';

const GC_1 = ({ props, setFavMsg }) => {

    const params = useLocation();

    const [device, setDevice] = useState();
    const [ls, setLs] = useState();

    async function fetchConfig() {
        const propertyId = User.getPropertyId();
        // console.log(propertyId);
        const result = await UserService.getConfig(propertyId);
        // console.log(result.devices);
        const filt = [...result.devices].filter(item => item.applianceId == props.applianceId);
        setDevice({ ...filt[0] });
        // console.log(filt);
        // console.log(params);

        const laststates = await UserService.getLastStatebydeviceId(
            propertyId, props.deviceId
        );
        // console.log(laststates);
        setLs({ ...laststates });

    }
    function controlToggleOne() {
        // console.log('first')
    }

    useEffect(() => {
        // console.log('W2CM_1', props.applianceId);
        fetchConfig();
    }, []);

    // console.log(device ?? 'no config');
    // console.log(ls ?? 'no ls')

    return (
        <div style={{ display: 'flex', justifyContent: 'space-evenly', padding: '0px 30px 0px 0px' }}>
            <GC_gate />
            <div className='wlc_timeline_view_container' >
                <TimeLineView applianceId={device?.applianceId} />
                
            </div>
        </div>
    )
}

export default GC_1