import React, { useEffect, useState } from 'react'
import { useUser } from '../../Context/context'

const SelectDevices = ({devices, setDevices}) => {
    const { allDevices } = useUser();

    useEffect(()=>{
        // console.log(devices);
    },[])
    
    const handleOn=(appliance_id)=>{
        const j = [...devices.off].indexOf(appliance_id);
        if(j>=0)
            devices.off.splice(j, 1);
        devices.on.push(appliance_id);
        setDevices({...devices})
    }
    const handleOff=(appliance_id)=>{
        const i = [...devices.on].indexOf(appliance_id);
        if(i>=0)
            devices.on.splice(i, 1);
        devices.off.push(appliance_id);
        setDevices({...devices})
    }
    const handleDefault=(appliance_id)=>{
        const i = [...devices.on].indexOf(appliance_id);
        const j = [...devices.off].indexOf(appliance_id);
        if(i>=0)
            devices.on.splice(i, 1);
        if(j>=0)
            devices.off.splice(j, 1);
        setDevices({...devices})
    }
    console.log(allDevices);
    return (
        <div style={{padding:'20px', display: 'flex', flexDirection:'column', gap:'50px', overflow:'scroll' }}>
            {[...allDevices].map((device, index) => <div key={index+'sd'} style={{ display: 'flex', justifyContent: 'space-between' }}>
                <div>{device.name}</div>
                <div><sub>{device.room} | {device.floor}</sub></div>

                <div className="dropdown1">
                    {
                        [...devices?.on].includes(device.applianceId)
                        ? <div className='select-devices-btns' style={{background:'#26a69a'}} >ON</div>
                        : [...devices?.off].includes(device.applianceId)
                        ? <div className='select-devices-btns' style={{ background:'#ff4d4d'}} >OFF</div>
                        : <div className='select-devices-btns' style={{ background:'#e6e6e6'}} >Default</div>
                    }
                    
                    <div className="dropdown-content">
                        <p style={{cursor:'pointer'}} onClick={()=>handleDefault(device.applianceId)}>Default</p>
                        <p style={{cursor:'pointer'}} onClick={()=>handleOn(device.applianceId)}>On</p>
                        <p style={{cursor:'pointer'}} onClick={()=>handleOff(device.applianceId)}>Off</p>
                    </div>
                </div>

            </div>)}
        </div>
    )
}

export default SelectDevices