import React, { useEffect } from "react";
import {
    Card,
    Col,
    Row,
    Typography,
    Timeline,
    Button,
} from "antd";
import { useState } from "react";
import { MenuUnfoldOutlined } from "@ant-design/icons";
import { UserService } from "../../services/UserService";
import moment from "moment";
import Paragraph from "antd/lib/typography/Paragraph";
import { DatePicker } from "antd";
import ThemeColor from "../../assets/theme/ThemeColor";
import { useUser } from "../../Context/context";

const TimeLineView = ({ applianceId }) => {
    const {device} = useUser();
    applianceId=applianceId ?? device.applianceId;
    const [data, setData] = useState([]);
    const [reverse, setReverse] = useState(false);
    // const [dates, setDates] = useState([]);
    const { Title, Text } = Typography;
    const [dates, setDates] = useState({ from: '', to: '' });


    useEffect(() => {
        var dates = [];
        dates.push(moment.utc().subtract(1, "days").format());
        dates.push(moment.utc().format());
        getMetricsData(dates);
    }, []);

    function onSubmmit() {
        getMetricsData([dates.from, dates.to]);
    }

    async function getMetricsData(dates) {
        // console.log(applianceId);
        // console.log([...dates]);
        try {
            const result = await UserService.getTimeline(
                applianceId,
                dates
            );
            // console.log(result)
            if (result) {
                setData(result);
            }
        } catch (error) {
            console.log("error...", error);
        }
    }

    //Apply function to get the data out of getMetrics data


    //It takes the data and verify the given date
    // function handleDates(dates, index) {
    //     if (dates && dates.moment) {
    //         console.log(dates.moment.utc().format());
    //     }
    //     console.log(index);
    // }
    function handleDates(e, index) {
        if (e?._d && e?._d.moment) {
            const date = moment(e._d);
            const formattedDate = date.format('YYYY-MM-DD');
            if (index === 0) {
                dates.from = formattedDate
            } else if (index === 1) {
                dates.to = formattedDate
            }
        } else {
            dates.from = '';
            dates.to = "";
        }
        setDates({ ...dates });


    }

    return (
        <div >
            <Title level={5} style={{ marginBottom: 24 }}>
                Usage History
            </Title>
            <div>
                {/* <Title level={5} style={{ marginBottom: 24,fontSize:14 }}>
                                    Select Start and End DateTime
                                </Title> */}
                {[1, 2].map((data, index) => {
                    return (
                        <div style={{ display: 'flex', flexWrap: 'wrap', alignItems: 'center', marginLeft: 50 }} key={index}>
                            <p style={{ flex: '1 0 112px' }}>
                                {index ? "End Date-Time" : "Start Date-Time"}
                            </p>
                            <DatePicker
                                showTime
                                style={{ width: "65%", marginBottom: 10 }}
                                onChange={(e) => handleDates(e, index)}
                                value={dates[index]}
                            />
                        </div>
                    );
                })}
                <div style={{ marginTop: "20px", }}>
                    <button
                        type="primary"
                        className="width-100"
                        onClick={() => onSubmmit()}
                        style={{ backgroundColor: ThemeColor.hashhPrimary, border:'none', color:'white', padding:'5px' }}
                    >
                        Apply
                    </button>
                </div>
            </div>
            <div className="timeline-box" style={{ marginTop: "20px", }}>
                <Timeline style={{ overflowY: 'scroll', height: '150px', padding: '10px 0px 0px 0px', }}
                    pending="updating..."
                    className="timelinelist"
                    reverse={reverse}
                >
                    {data.map((t, index) => (
                        <Timeline.Item key={index} >
                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                <Title style={{ marginRight: '20px', fontSize:'12px' }} level={20}>
                                    {moment(t.time).format("ddd MMM DD/MM | hh:mm a")}
                                </Title>
                                {t.status ? (
                                    <Text style={{ color: "black" }}>(Open)</Text>
                                ) : (
                                    <Text style={{ color: "black" }}>(Close)</Text>
                                )}
                            </div>
                        </Timeline.Item>
                    ))}
                </Timeline>
                <Button
                    type="primary"
                    className="width-100"
                    onClick={() => setReverse(!reverse)}
                    style={{ backgroundColor: ThemeColor.hashhPrimary }}
                >
                    {<MenuUnfoldOutlined />} REVERSE
                </Button>
            </div>
        </div>
    );
};
export default TimeLineView;
