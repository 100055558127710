import React, { useState, useEffect } from 'react';
import { IoChevronDownOutline } from "react-icons/io5";
import ThemeColor from '../../../assets/theme/ThemeColor';
import { useUser } from '../../../Context/context';
import UpdateRoutine from './UpdateRoutine';

const DeviceChangeContainer = ({ device }) => {
    const [isOpen, setIsOpen] = useState(false);
    const [btnColor, setBtnColor] = useState('none');
    const { routineDevices, setRoutineDevices } = useUser();

    const handleOnChange = (e, applianceId) => {
        const value = e.target.value
        if (value === 'ON') {
            const index = routineDevices.off.indexOf(applianceId);
            if (index != '-1')
                routineDevices.off.splice(index, 1);
            routineDevices.on.push(applianceId);
            const routineDevicesOn = new Set(routineDevices.on);

            routineDevices.on = [...routineDevicesOn];
            setBtnColor('green');
        }
        else if (value === 'OFF') {
            const index = routineDevices.on.indexOf(applianceId);
            if (index != '-1')
                routineDevices.on.splice(index, 1);
            routineDevices.off.push(applianceId);
            const routineDevicesOff = new Set(routineDevices.off);
            routineDevices.off = [...routineDevicesOff];
            setBtnColor('red');
        }
        setRoutineDevices({ ...routineDevices });
    };
    const a = [...routineDevices.on].includes(device.applianceId)
    ? false
    : [...routineDevices.off].includes(device.applianceId) 
        ? false
        : true
        
    return (
        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', height: '100%', overflow: 'scroll', position: 'relative' }}>
            <div style={{ width: '50%', marginBottom: '20px' }}>
                <h3 >{device.name}</h3>
                <h5>{device.floor} | {device.room}</h5>
            </div>
            <div style={{ position: 'relative' }}>
                <select style={{
                    width: '120px',
                    padding: '5px',
                    fontSize: '14px',
                    borderRadius: '5px',
                    color: [...routineDevices.on].includes(device.applianceId) ? ThemeColor.white : [...routineDevices.off].includes(device.applianceId) ? ThemeColor.white : 'black',
                    border: '1px solid #ccc',
                    backgroundColor: [...routineDevices.on].includes(device.applianceId) ? ThemeColor.darkgreen : [...routineDevices.off].includes(device.applianceId) ? ThemeColor.darkred : null
                }}
                    onChange={(e) => handleOnChange(e, device.applianceId)}
                >
                    <option selected={
                        [...routineDevices.on].includes(device.applianceId)
                            ? false
                            : [...routineDevices.off].includes(device.applianceId) 
                                ? false
                                : true
                        } 
                        value="-">DEFAULT</option>
                    <option
                        selected={
                            [...routineDevices.on].includes(device.applianceId)
                                ? true
                                : false
                            } 
                        value="ON">ON</option>
                    <option 
                        selected={
                            [...routineDevices.off].includes(device.applianceId)
                                ? true
                                : false
                            } 
                        value="OFF">OFF</option>
                </select>
                <IoChevronDownOutline style={{ cursor: 'pointer', position: 'absolute', color: [...routineDevices.on].includes(device.applianceId) ? ThemeColor.white : [...routineDevices.off].includes(device.applianceId) ? ThemeColor.white : 'black', right: '10px', top: '10px' }} />
            </div>
        </div>
    );
};

export default DeviceChangeContainer;
