import React, { useEffect } from 'react'
import ToggleSwitch from '../../../components/common/ToggleSwitch';
import { useUser } from '../../../Context/context';
import { FaTrash } from "react-icons/fa";
import { useToggleTop } from '../../../components/common/ToggleTop';
import { UserService } from '../../../services/UserService';
import { convertUTCToIST } from './AddSchedule';

const ScheduleCard = ({ data, color, setDrawer, isDelete, getSchedules }) => {
    const {scheduleG, setScheduleG} = useUser();
    const setToggleTop = useToggleTop();
    color= color ?? 'grey'
    const { device } = useUser();
    const weekDays = {
        0: 'Sun',
        1: 'Mon',
        2: 'Tue',
        3: 'Wed',
        4: 'Thu',
        5: 'Fri',
        6: 'Sat',
    }
    
    const openBottomSheet=()=>{
        setScheduleG({...data, applianceId:device.applianceId, deviceId:device.deviceId, deviceType:device.deviceType, state:'update'});
        setDrawer('open');
    }
    function handleToggleSwitch(e){
        handleRoutineStatus(data._id, e.target.checked);
    }
    const handleRoutineStatus = async (id, status) => {
        try {
          const result = await UserService.enableRoutine(id, { disable: !data.disabled });
          setToggleTop("Schedule status Changed", 'green');
          getSchedules();
        } catch (e) {
            setToggleTop("Schedule status not changed !", 'red');
          console.log(e);
        }
      }
    const deleteRoutineCard = async (id, name) => {
        if (window.confirm(`Are you sure you want to delete routine '${name}' ?`) !== false) {
          try {
            const result = await UserService.deleteRoutine(id);
            setToggleTop("Schedule Deleted !", 'green');
            getSchedules();
          } catch (e) {
            console.log(e);
            setToggleTop('Schedule not Deleted', 'red');
          }
        } else  {
          setToggleTop("Schedule Not deleted", "blue");
        }
      }
    
    return (
        <div
            style={{
                display: 'flex',
                justifyContent: 'end',
                backgroundColor: color,
                borderRadius: '12px',
                boxShadow: 'rgba(0, 0, 0, 0.133) 0px 0px 5px 1px',
                cursor:'pointer'
                // border:'1px solid black'
            }}>
            <div style={{ width: '90%', backgroundColor: 'white', width: '250px', borderRadius: '0px 12px 12px 0px', padding: '10px', position: 'relative' }}>
                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <div onClick={openBottomSheet}>{data.data.name}</div>
                    <div><ToggleSwitch checked={!data.disabled} color={color} onChange={handleToggleSwitch}/></div>
                </div>
                <div onClick={openBottomSheet}>
                    <div>{convertUTCToIST(data.data.time)}</div>
                    <div>
                        {data?.data?.weekDays.map((item, index) => <small key={`weekdays${index}`}>{weekDays[item]}&nbsp;&nbsp;&nbsp;</small>)}
                    </div>
                    <div>Device : {data?.data?.control?.on.includes(device.applianceId) ?
                        'ON' : data?.data?.control?.off.includes(device.applianceId) ? "OFF" : null}
                    </div>
                </div>
                {isDelete && <FaTrash onClick={()=>deleteRoutineCard(data._id, data.data.name)} style={{ position: 'absolute', color: 'rgb(108, 19, 9)', height: '30px', width: '20px', bottom: '10px', right: '10px', cursor:'pointer' }} />}
            </div>
        </div>
    )
}

export default ScheduleCard