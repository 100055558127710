import React, { useEffect, useRef, useState } from 'react'
import { useUser } from '../../Context/context'
import { UserService } from '../../services/UserService';
import { User } from '../../storage/store';
import { useToggleTop } from '../common/ToggleTop';
import ThemeColor from '../../assets/theme/ThemeColor';

const SwitchSettings = () => {
  const { device, setDevice } = useUser();
  const setToggleTop = useToggleTop();
  const [config, setConfig] = useState();
  const [payload, setPayload] = useState();
  const [roomName, setRoomName] = useState();
  const [floorName, setFloorName] = useState();

  const nameRef = useRef();
  const deviceIpVoltageRef = useRef();
  const deviceIpCurrentRef = useRef();
  const nameSubRef = useRef();
  const currentSubRef = useRef();
  const voltageSubRef = useRef();

  const handleDeviceDelete=async()=>{
   const confirmDelete = await window.confirm(`Confirm delete '${device?.name}'`);
   if(confirmDelete){
    try{
      const propertyId = User.getPropertyId();
      // const response = await UserService.deleteDevice(propertyId, device.deviceId);
      // if(response){
        setToggleTop(`'${device?.name}' Deleted successfully !`, 'green');
      // }
      
     }catch(e){
      setToggleTop("Failed Deleting Device", 'red');
     }
   }else{
    setToggleTop(`${device?.name} not Deleted`, 'red')
   }
  
  }

  const handleUpdate = async () => {
    let name = nameRef.current.value;
    let current = Number(deviceIpCurrentRef.current.value);
    let voltage = Number(deviceIpVoltageRef.current.value);

    if(!name || !current || !voltage){
      if(!name ){
        nameRef.current.style.borderBottom = '1px solid red';
        nameSubRef.current.style.visibility = 'visible';
      }else{
        nameRef.current.style.borderBottom = '1px solid black';
        nameSubRef.current.style.visibility = 'hidden';
      }

      if(!current ){
        deviceIpCurrentRef.current.style.borderBottom = '1px solid red';
        currentSubRef.current.style.visibility = 'visible';
      } else{
        deviceIpCurrentRef.current.style.borderBottom = '1px solid black';
        currentSubRef.current.style.visibility = 'hidden';
      }

      if(!voltage){
        deviceIpVoltageRef.current.style.borderBottom = '1px solid red';
        voltageSubRef.current.style.visibility = 'visible';
      }else{
        deviceIpVoltageRef.current.style.borderBottom = '1px solid black';
        voltageSubRef.current.style.visibility = 'hidden';
      }
      
    }else{
      const propertyId = User.getPropertyId();
      nameRef.current.style.borderBottom = '1px solid black';
      nameSubRef.current.style.visibility = 'hidden';
      deviceIpCurrentRef.current.style.borderBottom = '1px solid black';
      currentSubRef.current.style.visibility = 'hidden';
      deviceIpVoltageRef.current.style.borderBottom = '1px solid black';
      voltageSubRef.current.style.visibility = 'hidden';
      let __payload ={
        ...payload,
        name,
        meta:{
          ...payload?.meta,
          current,
          voltage
        }
      }
      
      // console.log(__payload);
      try{
        const response = await UserService.updateDevice(__payload, propertyId);
        if(response)
          setToggleTop(response.msg, 'green');
        else
          setToggleTop('Something went Wromg !', 'red');
      }catch(e){
        setToggleTop('Something went Wromg !', 'red');
      }
    }
    
  }

  useEffect(()=>{
    (async()=>{
      const PropertyId = User.getPropertyId();
      setPayload({...device});
      const _config = await UserService.getConfig(
        PropertyId
      )
      setConfig(_config);
      // console.log(_config);
    })()
  }, []);
  // console.log(payload);
  return (
    <div style={{
      display:'flex',
      flexDirection:'column',
      padding:'10px 0px 0px 20px',
      gap:'30px'
    }}>
      <div style={{
        position:'absolute',
        padding:'5px',
        right:'10px',
        top:'16px',
      }}
      onClick={handleUpdate}
      disabled={true}
      >
        <button 
          className={!(floorName||roomName) ? 'pointer' : ''} 
          style={
            (floorName||roomName)
            ? {border:'none',color:'white', background:ThemeColor.lightgray, borderRadius:'3px',}
            : {border:'none',color:'white', background:ThemeColor.pirSecondary, borderRadius:'3px',}
          }
          disabled={floorName || roomName}
        >
          Update
        </button>
      </div>
      <div>
        <div className='switchSettings-name'>Name</div>
        <div>
          <input 
            type='text' 
            ref={nameRef}
            className='switchSettings-input'
            defaultValue={device?.name}
          />
        </div>
        <sub ref={nameSubRef} style={{color:'red', visibility:'hidden'}}>Not a valid name</sub>
        </div>

      <div>
        <div className='switchSettings-name'>Room</div>
        <div>
          <input 
            type='text' 
            readOnly
            className='switchSettings-input'
            defaultValue={payload?.room}
            onClick={()=>setRoomName(true)}
          />

          {roomName && <div 
            className='opacity-transition' 
            style={{
              display:'flex', 
              flexDirection:'column', 
              textAlign:'center', 
              background:'white', 
              position:'absolute', 
              borderRadius:'5px', 
              width:'90%',  
              boxShadow:'1px 1px 10px -1px  grey',
              padding:'10px 0px 0px 0px',
            }}
          >
            <div style={{fontSize:'1.2em', fontWeight:'500'}}><u>Choose Room Name</u></div>
            {config?.floors.map((floor)=>{
              if(floor._id===payload.floorId)
                return <div key={floor._id} style={{display:'flex', flexDirection:'column', gap:'15px', padding:'7px'}}>
                  {floor?.rooms.map((room)=><div 
                    onClick={()=>{
                      payload.roomId = room?._id;
                      payload.room = room.name;
                      setPayload({...payload});
                      setRoomName(false);
                    }}
                    className='pointer' 
                    style={payload.roomId===room._id?{ padding:'5px', background:'#BB8BF7', color:'white'}: { padding:'5px'}} 
                    key={room._id}>
                      {room.name}
                  </div>)}
                </div>
            })}
          </div>}

        </div>
      </div>

      <div>
        <div className='switchSettings-name'>Floor</div>
        <div>
          <input 
            id={device?.floorId}
            type='text' 
            className='switchSettings-input'
            defaultValue={device?.floor}
            readOnly
            onClick={()=>setFloorName(true)}
          />
          {floorName && <div style={{
            display:'flex', 
            flexDirection:'column', 
            textAlign:'center', 
            background:'white', 
            position:'absolute', 
            borderRadius:'5px', 
            width:'90%',  
            boxShadow:'1px 1px 10px -1px  grey',
            margin:'20px auto',
            padding:'10px 0px 0px 0px'
          }}>
            <div style={{fontSize:'1.2em', fontWeight:'500'}}><u>Choose Floor Name</u></div>
            {config?.floors.map((floor)=>{
              return <div key={floor?._id} style={{display:'flex', flexDirection:'column', gap:'15px', padding:'7px 0px 0px 0px'}}>
                <div 
                  onClick={()=>{
                    payload.floorId = floor?._id;
                    payload.floor = floor.name;
                    setPayload({...payload});
                    setFloorName(false);
                    setRoomName(true);
                  }}
                  className='pointer' 
                  style={payload.floorId===floor._id?{ padding:'5px', background:'#BB8BF7', color:'white'}: { padding:'5px'}} 
                  key={floor._id}>
                    {floor.name}
                </div>
              </div>
            })}
          </div>}
        </div>
      </div>

      <div>
        <div className='switchSettings-name'>Device Type</div>
        <div>
          <input 
            type='text' 
            className='switchSettings-input'
            defaultValue={device?.deviceType}
            readOnly={true}
            style={{
              backgroundColor:'rgb(238 235 203 / 85%)'
            }}
          />
        </div>
      </div>

      <div>
        <div className='switchSettings-name'>Device IP Voltage</div>
        <div>
          <input 
            ref={deviceIpVoltageRef}
            type='text' 
            className='switchSettings-input'
            defaultValue={device?.meta?.voltage}
          />
        </div>
        <sub ref={voltageSubRef} style={{color:'red', visibility:'hidden'}}>Not a valid data</sub>
        </div>

      <div>
        <div className='switchSettings-name'>Device IP Current</div>
        <div>
          <input 
            type='text' 
            ref={deviceIpCurrentRef}
            className='switchSettings-input'
            defaultValue={device?.meta?.current}
            // onChange={()=>}
          />
        </div>
        <sub ref={currentSubRef} style={{color:'red', visibility:'hidden'}}>Not a valid data</sub>
      </div>

      <p style={{fontSize:'20px', fontWeight:'100'}}>Power: {device?.meta?.voltage * device?.meta?.current} W</p>

      <div style={{
        display:'flex',
        justifyContent:'center',
        textAlign:'center'
      }}>
        <div
        onClick={handleDeviceDelete}
          style={{
            // border:'1px solid red',
            padding:'5px 50px',
            fontSize:'20px',
            color:'red',
            cursor:'pointer'
          }}
        >DELETE SWITCH</div>
      </div>
      
    </div>
  )
}

export default SwitchSettings