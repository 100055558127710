import React, { useEffect, useState } from 'react'
import Main from '../layout/Main';
import { useLocation } from "react-router-dom";
import WLC_1 from './WLC/WLC_1';
import SwitchCard from './SwitchComponent';
import WCM_1 from './CurtainModels/WCM_1';
import GC_1 from './GC/GC_1';
import DetailFrame from '../DetailFrame';
import { User } from '../../storage/store';
import { useUser } from '../../Context/context';
import { UserService } from '../../services/UserService';
import CircleBarSpinner from '../common/CircleBarSpinner';
import { useToggleTop } from '../common/ToggleTop';
import DotSpinner from '../common/DotSpinner';
import Dimmer from './DimmerModules/Dimmer';
import SwitchDetail from './SwitchDetails';

const ControllerDetail = () => {
    const params = useLocation();
    const navigatedDevice = params?.state?.data;
    const [trigger, setTrigger] = useState(false);
    const [Ls, setLs] = useState();
    const [activeWLCMotor, setActiveWLCMotor] = useState(1);
    const setToggleTop = useToggleTop();



    const { device, setDevice, setDeviceSettings } = useUser();
    useEffect(()=>{
        setActiveWLCMotor(1);
    },[]);

    useEffect(() => {
        setDevice(null);
        construct();
        setDeviceSettings(<>
            <div style={{height:'80lvh', textAlign:'center', alignItems:'center'}}>
                <div>
                    <DotSpinner/>
                    Coming Soon ...
                </div>
            </div>
            </>)
    }, [trigger]);

    async function construct() {
        const propertyId = User.getPropertyId();
        const deviceId = navigatedDevice.deviceId;

        const result = await UserService.getConfig(propertyId);
        const filt = [...result.devices].filter(item => item.applianceId == params.state.data.applianceId);
        let _device = filt[0] ?? navigatedDevice;
        try {
            const ls = await UserService.getLastStatebydeviceId(propertyId, deviceId);
            setLs(ls)
            if (_device.deviceType === "WATER_LEVEL_CONTROLLER" && (_device.waterFlowType == "TYPE_1" || _device.waterFlowType == "TYPE_2" || _device.waterFlowType == "TYPE_3" || _device.waterFlowType == "TYPE_6")) {
                _device['motorsConfig'][`${_device.applianceId}-1`]['state'] = ls['devices'][`${_device.applianceId}-1`]['state']
                _device['motorsConfig'][`${_device.applianceId}-2`]['state'] = ls['devices'][`${_device.applianceId}-2`]['state']
                _device.props.state = ls['devices'][`${_device.applianceId}-${activeWLCMotor}`]['state'];
                
                setDevice({ ..._device, build: ls.build, firmwareVersion: ls.firmwareVersion, devices: ls.devices });

            } else {
                setDevice({ ..._device, build: ls.build, firmwareVersion: ls.firmwareVersion });
            }

        } catch (e) {
            console.log(e);
            _device.props.state = false;
            setDevice({ ..._device, build: null, firmwareVersion: null });

        }
    }

    const controlDevice=()=>{
        let payload1={};
        if(device.deviceType === "WATER_LEVEL_CONTROLLER"){
            payload1= {
                    on:[device.applianceId],
                    off:[],
                    action:{
                        [device.applianceId]:{
                            id: activeWLCMotor,
                            trait:701,
                            value:!Number(device.props.state)
                        }
                    }
                }
        }

        try{
            const properteyId = User.getPropertyId();
            const response = UserService.stateUpdate(payload1, properteyId);
            setToggleTop('Success', 'green');
            // setTrigger(!trigger);
            }catch(e){
                setToggleTop('Failed', 'red');
            }
    }
    
    return (
        <>
            <Main>
                <DetailFrame control={controlDevice}>
                    {device
                        ? <>
                            {
                                device.deviceType === "WATER_LEVEL_CONTROLLER"
                                    ? <WLC_1 props={device} setTrigger={setTrigger} ls={Ls} setActiveWLCMotor={setActiveWLCMotor}/>
                                    : device.deviceType === "CURTAIN_MODULE"
                                        ? <WCM_1 props={device} setTrigger={setTrigger} />
                                        : device.deviceType === "SLIDING_GATE"
                                            ? <GC_1 props={device} setTrigger={setTrigger} />
                                            : device.deviceType === "SWING_GATE"
                                                // ? <Dimmer props={device} setTrigger={setTrigger} />
                                                ? <GC_1 props={device} setTrigger={setTrigger} />
                                                : device.deviceType === "DIMMER_MODULE"
                                                    ? <Dimmer props={device} setTrigger={setTrigger} />
                                                    : < Notice />
                            }
                        </>
                        : <CircleBarSpinner />
                    }
                </DetailFrame>
            </Main>
        </>
    )
}
const Notice = () => (

    <div>
        <h1 style={{ textAlign: 'center' }}>Support Coming Soon...</h1>
        <p style={{ textAlign: 'center' }}>This device is not yet supported. Schedules added on this time won't work. Please check back later for updates.</p>
    </div>
);


export default ControllerDetail