import React from 'react'
import CircleBarSpinner from '../common/CircleBarSpinner'

const BackgroundCard = ({children, height, background, backgroundColor}) => {
    height = height ?? '100%';
    background = background ?? backgroundColor ?? 'white'
  return (
    <div style={{background, padding:'17px', height, width:'100%', borderRadius:'10px', boxShadow:'1px 1px 20px -15px grey', overflow:'scroll'}}>
        {children ? children : <CircleBarSpinner/>}
    </div>
  )
}

export default BackgroundCard