import React, { useEffect } from "react";
import {
  Card,
  Typography,
  Button,
  message,
  Drawer,
  Input,
  Form,
} from "antd";
import { useLocation } from "react-router-dom";
import { useState } from "react";
import { UserService } from "../../services/UserService";
import moment from "moment";
import "react-datetime-picker/dist/DateTimePicker.css";
import "react-calendar/dist/Calendar.css";
import "react-clock/dist/Clock.css";
import "../../assets/styles/main.css";
import "../../assets/styles/responsive.css";
import Main from "../layout/Main";
import MonthChart from "../chart/Sensor-Charts/MonthChart";
import YearChart from "../chart/Sensor-Charts/YearChart";
import TimeLineView from "./TimeLineView";
import SwitchInfo from "./SwitchInfo";
import { useToggleTop } from "../common/ToggleTop";
import DetailFrame from "../DetailFrame";
import SwitchSettings from "./SwitchSettings";
import { useUser } from "../../Context/context";
import { User } from "../../storage/store";
import { MdSensors } from "react-icons/md";
import { IoLockClosed } from "react-icons/io5";

const SensorDetails = ({ props }) => {
  const params = useLocation();
  const navigatedDevice = params?.state?.data;
  const [data, setData] = useState([]);
  const { Title, Text } = Typography;
  const [messageApi, contextHolder] = message.useMessage();
  // const [favourite, setFavourite] = useState(device.favourite ? device.favourite : false);
  const [Ls, setLs] = useState();

  const [chartType, setChartType] = useState("Month");

  const setToggleTop = useToggleTop();

  const handleChartTypeChange = (newChartType) => {
    setChartType(newChartType);
  };

  //Modify the given Date
  useEffect(() => {
    var dates = [];
    dates.push(moment.utc().subtract(1, "days").format());
    dates.push(moment.utc().format());
    getMetricsData(dates);
  }, []);

  //To get the Metrics Data od the door sensor
  async function getMetricsData(dates) {
    // console.log(dates);
    try {
      const result = await UserService.getTimeline(
        params.state.data.applianceId,
        dates
      );
      if (result) {
        setData(result);
      }
    } catch (error) {
      console.log("error...", error);
    }
  }

  //Side Slidewindow input values
  const [inputValue, setInputValue] = useState(params.state.data.name);
  const [inputValue2, setInputValue2] = useState(params.state.data.site);
  const [inputValue3, setInputValue3] = useState(params.state.data.location);
  const {device, setDevice, setDeviceSettings, activePayload, setActivePayload} = useUser();
  const [trigger, setTrigger] = useState(false);

  const handleChange = (event) => {
    setInputValue(event.target.value);
  };
  const handleChange2 = (event) => {
    setInputValue2(event.target.value);
  };
  const handleChange3 = (event) => {
    setInputValue3(event.target.value);
  };
  useEffect(() => {
    setDevice('');           
    construct();
  }, [trigger]);

  async function construct() {
    try {
      // console.log(device);
      const propertyId = User.getPropertyId();
      const deviceId = navigatedDevice.deviceId;

      // console.log(deviceId);

      const result = await UserService.getConfig(propertyId);
      // console.log(result.devices);
      const filt = [...result.devices].filter(item => item.applianceId == params.state.data.applianceId);
      // console.log(filt[0]);
      let _device = filt[0] ?? navigatedDevice ?? {};
      // getUserConfig();
      console.warn(result);
      // console.log(_device);

      const ls = await UserService.getLastStatebydeviceId(propertyId, deviceId);
      // console.log(ls['devices'][_device?.applianceId]);
      // console.log(ls);
      setLs(ls['devices'][_device?.applianceId]);
      _device.props.state = ls['devices'][_device.applianceId]['state'];
      _device.build=ls?.build;
      _device.firmwareVersion=ls?.firmwareVersion; 
      _device.rssi=ls?.rssi
      if(_device){
        // console.log('sdetails',_device);
        setDevice({ ..._device});
      }else{
        setDevice({});
      }
      
      // console.log(ls['devices'][_device.applianceId]['state'])
      setDeviceSettings(<SwitchSettings/>);
    } catch (e) {
      console.log(e);
    }
  }
  const controlDevice=()=>{
    let payload1;
    if(device.props.state){
      payload1 ={
        on:[],
        off:[device.applianceId]
      }
    }else{
      payload1 ={
        on:[device.applianceId],
        off:[]
      }
    }
    // console.log(payload1);
    try{
        const properteyId = User.getPropertyId();
        const response = UserService.stateUpdate(payload1, properteyId);
        setToggleTop('Success', 'green');
    }catch(e){
        setToggleTop('Failed', 'red');
    }
  }
  
  async function handleClick() {
    const storedValue = inputValue;
    const storedValue1 = inputValue2;
    const storedValue2 = inputValue3;

    const payload = {
      ...device,
      name: storedValue,
      site: storedValue2,
      location: storedValue1,
    };
    try {
      const response = await UserService.updateDevice(
        payload,
        params.state.propertyId
      );
      messageApi.open({
        type: "success",
        content: "Saved successfully",
        duration: 10,
      });
      console.warn(response);
    } catch (error) {
      console.warn(error);
      messageApi.open({
        type: "error",
        content: "Something Went Wrong",
        duration: 10,
      });
    }
  }

// console.log(device);
// console.log(activePayload);
  return (
    <>
      
      <Main>
        <div className="layout-content">
          <DetailFrame control={controlDevice}>
            <div className="switchdetails-main-card">
                <div style={{ width: '60%', height: '100%', margin: '0 auto', justifyContent: 'center', alignSelf: 'center', }}>
                  <div style={{ fontSize: "200px", textAlign: 'center' }} >
                    {device ? (
                      device.deviceType === "PIR_SENSOR" ? (
                        <MdSensors style={{ color: 'black' }} />
                      ) : device.deviceType === "DW_SENSOR" ? (
                        <IoLockClosed style={{ color: 'black' }} />
                      ) : (
                        <MdSensors style={{ color: 'black' }} />
                      )
                    ) : <MdSensors style={{ color: 'black' }} />}
                  </div>
                </div>
                <div className="switchdetails-main-card-vert-hortizon-line" ></div>
                <div className="switchdetails-main-card-TimeLineView-parent">
                  <TimeLineView
                    applianceId={params.state.data.applianceId}
                  />
                </div>
              </div>
          </DetailFrame>
          {/* <Card
            style={{
              height: "100%",
              width: "100%",
            }}
            extra={
              <>
                {" "}
                <RiHeart3Fill
                  style={{
                    color: favourite ? "red" : "grey",
                    fontSize: 28,
                    marginRight: "12px",
                    cursor: 'pointer'
                  }}
                  onClick={hearthandle}
                />
                <AiFillSetting
                  onClick={showDrawer}
                  style={{ fontSize: 28, marginRight: "2px" }}
                />{" "}
                <AiFillInfoCircle
                  onClick={showChildrenDrawer}
                  style={{
                    fontSize: 28,
                    marginLeft: "10px",
                    marginRight: "10px",
                  }}
                />
                <Drawer
                  title="Setting"
                  className="settings-drawer"
                  mask={true}
                  width={570}
                  onClose={hideDrawer}
                  placement={"right"}
                  visible={visible}
                  footer={
                    <Text style={{ color: "gray" }}>
                      <center>
                        Manufacturer <br /> HASHh Automations connected <br />
                        through HasHh Cloud
                      </center>
                    </Text>
                  }
                  footerStyle={{
                    fontSize: "18px",
                    fontWeight: "bold",
                    background: "white",
                  }}
                >
                  <Text
                    style={{
                      fontSize: "16px",
                      marginLeft: "15px",
                      fontWeight: "bold",
                    }}
                  >
                    Name
                  </Text>
                  <Input
                    placeholder="Enter Device Name"
                    value={inputValue}
                    onChange={handleChange}
                    style={{
                      borderRadius: "8px",
                      padding: "10px",
                      marginBottom: "15px",
                      marginTop: "10px",
                      marginLeft: "10px",
                    }}
                  />
                  <Text
                    style={{
                      fontSize: "16px",
                      marginLeft: "15px",
                      fontWeight: "bold",
                    }}
                  >
                    Site
                  </Text>
                  <Input
                    placeholder="Enter Site"
                    value={inputValue2}
                    onChange={handleChange2}
                    style={{
                      borderRadius: "8px",
                      padding: "10px",
                      marginBottom: "15px",
                      marginTop: "10px",
                      marginLeft: "10px",
                    }}
                  />
                  <Text
                    style={{
                      fontSize: "16px",
                      marginLeft: "15px",
                      fontWeight: "bold",
                    }}
                  >
                    Location
                  </Text>
                  <Input
                    placeholder="Enter Location"
                    value={inputValue3}
                    onChange={handleChange3}
                    style={{
                      borderRadius: "8px",
                      padding: "10px",
                      marginBottom: "15px",
                      marginTop: "10px",
                      marginLeft: "10px",
                    }}
                  />
                  <Text
                    style={{
                      fontSize: "16px",
                      marginLeft: "15px",
                      fontWeight: "bold",
                    }}
                  >
                    Device Type
                  </Text>
                  <Typography style={{ borderRadius: "8px" }}>
                    <pre
                      style={{
                        padding: "10px",
                        borderRadius: "8px",
                        width: "520px",
                        marginLeft: "10px",
                      }}
                    >
                      {params.state.data.deviceType === "0x01" ? "SWITCH" : " "}
                    </pre>
                  </Typography>
                  <Text
                    style={{
                      fontSize: "16px",
                      marginLeft: "15px",
                      fontWeight: "bold",
                    }}
                  >
                    Device IP Voltage
                  </Text>
                  <Typography style={{ borderRadius: "8px" }}>
                    <pre
                      style={{
                        padding: "10px",
                        borderRadius: "8px",
                        width: "520px",
                        marginLeft: "10px",
                      }}
                    >
                      {params.state.data.meta.voltage}
                    </pre>
                  </Typography>
                  <Text
                    style={{
                      fontSize: "16px",
                      marginLeft: "15px",
                      fontWeight: "bold",
                    }}
                  >
                    Device IP Current
                  </Text>
                  <Typography style={{ borderRadius: "8px" }}>
                    <pre
                      style={{
                        padding: "10px",
                        borderRadius: "8px",
                        width: "520px",
                        marginLeft: "10px",
                      }}
                    >
                      {params.state.data.meta.current}
                    </pre>
                  </Typography>
                  <center>
                    {contextHolder}
                    <Button
                      type="primary"
                      htmlType="submit"
                      style={{
                        borderRadius: "8px",
                        width: "520px",
                        height: "auto",
                        padding: "10px",
                        marginLeft: "10px",
                        marginTop: "15px",
                      }}
                      onClick={handleClick}
                    >
                      Save
                    </Button>
                    <Button
                      type="primary"
                      htmlType="submit"
                      style={{
                        borderRadius: "8px",
                        width: "520px",
                        height: "auto",
                        padding: "10px",
                        marginLeft: "10px",
                        marginTop: "15px",
                      }}
                      danger
                    >
                      Delete
                    </Button>
                  </center>
                </Drawer>
              </>
            }
          >
            {childrenDrawer ?
              <SwitchInfo
                isOpen={childrenDrawer}
                isClose={() => setChildrenDrawer(false)}
              />
              : null}
            <TimeLineView
              applianceId={params.state.data.applianceId}
            />
          </Card> */}
          {device?.deviceType === "DW_SENSOR" && <Card
            style={{
              height: "110vh",
              width: "100%",
              marginTop: "40px",
            }}
          >
            <Title style={{ textAlign: "center", fontSize: "25px" }}>
              Sensor Analytics
            </Title>
            <div
              className="container"
              style={{
                textAlign: "center",
                marginTop: "20px",
                marginBottom: "20px",
              }}
            >
              <div style={{display:'flex', justifyContent:'center', gap:'20px', padding:'20px',fontWeight:900, fontSize:'1.5em'}}>
                <div
                  // className={`chart-button ${chartType === "Month" ? "active" : ""}`}
                    style={chartType === "Month" ? {outline:'none', border:'none', background:'transparent', color:'#723ED8', borderBottom:'1px solid #723ED8', padding:'5px'} : {outline:'none', border:'none', background:'transparent', color:'grey', padding:'5px', cursor:'pointer'}}
                  onClick={() => handleChartTypeChange("Month")}
                >
                  Month
                </div>
                <div
                  // className={`chart-button ${chartType === "Year" ? "active" : ""}`}
                  style={chartType === "Year" ? {outline:'none', border:'none', background:'transparent', color:'#723ED8', borderBottom:'1px solid #723ED8', padding:'5px'} : {outline:'none', border:'none', background:'transparent', color:'grey', padding:'5px', cursor:'pointer'}}
                  onClick={() => handleChartTypeChange("Year")}
                >
                  Year
                </div>
              </div>

              {chartType === "Month" && <MonthChart />}
              {chartType === "Year" && <YearChart />}
            </div>
          </Card>}
        </div>
      </Main>
    </>
  );
};
export default SensorDetails;
