import React from 'react'

const RadioButton = ({lable, name, background, checked, onChange, type}) => {
    name = name ?? 'radio';
    background = background ?? '#ffffff';

    return (
        <>
            <label className="custom-radio">
                <input type="radio" name={name} checked={checked} onChange={()=>onChange(type)}/>
                <span style={{background}} className="checkmark"></span>
                {lable}
            </label>
        </>
    )
}

export default RadioButton