import React, { useEffect } from "react";
import { Link, useHistory } from "react-router-dom";
import { useState } from "react";
import ThemeColor from "../../assets/theme/ThemeColor";
import "../../assets/styles/main.css";
import "../../assets/styles/responsive.css";
import { IoLockClosed } from "react-icons/io5";
import { User } from "../../storage/store";
import TimeAgo from "../../pages/helpers.js/TimeAgo";
import { MdSensors } from "react-icons/md";

const SensorCard = ({ props }) => {
  const devices = props;
  console.log(devices);
  const navigate = useHistory();
  const [isHovering, setIsHovering] = useState(false);
  const [buttonColor, setButtonColor] = useState(ThemeColor.switchSecondary1);
  const [textColor, setTextColor] = useState(
    ThemeColor.switchTextColorIndustry
  );
  const [isHolding, setIsHolding] = useState(false);
  const PropertyId = User.getPropertyId();

  let holdTimer;

  const handleMouseDown = () => {
    holdTimer = setTimeout(() => {
      setIsHolding(true);
      navigate.push('SensorDetail', { data: devices, propertyId: PropertyId })
      console.log("On hold!");
    }, 1000);
  };

  const handleMouseUp = () => {
    clearTimeout(holdTimer);
    setIsHolding(false);
  };

  const handlemouseEnter = () => {
    setIsHovering(true);
  };

  const handlemouseLeave = () => {
    setIsHovering(false);
  };

  const main = {
    width: "auto",
    height: "100%",
    display: "grid",
    margin: "10px",
    borderRadius: "20px",
  };

  const header = {
    // left: '15px',
    // top: '15px',
    background: buttonColor,
    width: "150px",
    height: "180px",
    margin: "25px 10px",
    borderRadius: "20px",
    boxSizing: "borderBox",
    boxShadow: isHovering ? "0px 0px 10px 1px #0006" : "0px 0px 5px 1px #0002",
    border: `1.7px solid ${buttonColor}`,
    cursor: "pointer",
    // position: "relative",
    transition: "1000ms",
    AnimationDuration: "3s",
    AnimationIterationCount: "1",
    AnimationDirection: "reverse",
    appearance: "auto",
    AnimationName: "bounce",
    transition: "box-shadow .20s ease-in-out",
    transition: "background-position 0.5s ease-in-out",
  };

  useEffect(() => { }, []);

  return (
    <div
      // className="controllerdevice-header"
      style={header}
      onMouseEnter={handlemouseEnter}
      onMouseLeave={handlemouseLeave}
      onMouseDown={handleMouseDown} onMouseUp={handleMouseUp}
    >
      <div className="sensor-container-valueText">
        <b style={{ color: ThemeColor.white }}>{devices.props.value}</b>
        {devices.meta.model === "ISMD_1" ? 
        <MdSensors style={{color: 'white'}}/> 
        : <IoLockClosed style={{color:'white'}}/>}
      </div >
      <div style={{ color: "white" }}>
        <div
          style={{
            display: 'flex',
            color: textColor,
            marginLeft: "17px",
            height: '40px',
            alignItems: 'start',
            justifyContent: 'start',
            marginBottom: '10px',
            marginTop: '15px',
            overflow: 'hidden',
          }}
        >
          <b style={{ fontSize: '1em', alignSelf: 'end' }}>{devices.name}</b>
        </div>
        <div
          style={{
            color: textColor,
            marginLeft: "17px",
            fontSize: '0.7em',
            height: '15px',
            overflow: 'hidden',
          }}
        >
          <div>{devices.floor}&nbsp;|&nbsp;{devices.room}</div>
        </div>
        <div
          className="sensor-container-device-details"
          style={{
            color: textColor,
            textAlign: "start",
            marginLeft: "17px",
            overflowY: 'hidden',
            padding: '10px 0px 0px 0px',
            fontSize: '0.8em',
          }}
        >
          <div>{TimeAgo.getTimeAgo(devices.props.timeStamp)}</div>
        </div>
      </div>
    </div>
  );
};
export default SensorCard;
