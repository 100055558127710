import React, { useEffect, useState } from 'react'
import Main from '../../../components/layout/Main'
import { UserService } from '../../../services/UserService'
import { User } from '../../../storage/store';
import CircleBarSpinner from '../../../components/common/CircleBarSpinner';
import { MdOutlineTouchApp } from "react-icons/md";
import { SlOptions } from "react-icons/sl";
import { MdAddToPhotos } from "react-icons/md";
import { IoSunny } from "react-icons/io5";
import BackgroundCard from '../../../components/layout/BackgroundCard';
import { FaFire } from 'react-icons/fa';
import DoubleTapIcon from '../../../components/icons/DoubleTapIcon';
import ThemeColor from '../../../assets/theme/ThemeColor';
import BottomSheet from '../../../components/common/BottomSheet';
import SceneDetail from './SceneDetail';
import SideDrawer from '../../../components/common/SideDrawer';
import SelectDevices from '../../../components/common/SelectDevices';
import { useToggleTop } from '../../../components/common/ToggleTop';

const Scenes = () => {

    const [scenes, setScenes] = useState(null);
    const [bottomSheet, setBottomSheet] = useState('');
    const [sideDrawer, setSideDrawer] = useState('');
    const [selectedDevices, setSelectedDevices] = useState({ on: [], off: [] });
    const [selectedScene, setSelectedScene] = useState();

    const PropertyId = User.getPropertyId();

    const setToggleTop = useToggleTop();

    const construct = async () => {
        const response = await UserService.getScenes(PropertyId);
        setScenes([...response]);
        console.log('scee', response);
    }

    useEffect(() => {
        construct();
    }, []);
    console.log(scenes);
    const handleScene = async (data) => {
        if (Object.keys(data).includes('_id')) {
            const val = window.confirm(`Sure start ${data.name} scene?`);
            if (val) {
                // const response = UserService.triggerScene({ sceneId: data?._id });
                setToggleTop(`Scene '${data.name}' started.`, "green");
            }
            else
                setToggleTop("Scene not Triggered.", "red");
        }else console.log('not a scene');
    }
    return (
        <Main>
            <BackgroundCard background={'white'}>
                {scenes ?
                    scenes.length ?
                        <div
                            className='grid-container-sc'
                            style={{
                                display: 'flex',
                                flexWrap: 'wrap',
                                gap: '20px',
                                justifyContent: 'start'
                            }}
                        >
                            {[...scenes]?.map((item, index) => <div
                                style={{
                                    backgroundColor: ThemeColor.quickActions[index % ThemeColor.quickActions.length],
                                    padding: '15px 20px',
                                    borderRadius: '8px',
                                    minWidth: '200px',
                                    maxWidth: '200px',
                                    height: '180px',
                                    display: 'flex',
                                    flexDirection: 'column',
                                    justifyContent: 'space-between',
                                    gap: '20px',
                                }}
                            >
                                <div style={{ display: 'flex', justifyContent: 'space-between', position: 'relative' }}>
                                    {item.sceneType=== "tapToStart" ?<div className='light-font' onClick={()=>handleScene(item)}>
                                        <DoubleTapIcon style={{
                                            padding: '10px',
                                            height: '50px',
                                            width: '50px',
                                            background: 'white',
                                            borderRadius: '50%',
                                            fontSize: '65px',
                                            cursor: 'pointer',
                                            display: 'flex',
                                            justifyContent: 'center',
                                            alignItems: 'center'
                                        }} />
                                    </div>
                                    :
                                    item.sceneType=== "sunRiseSunSet" ?
                                    <div className='light-font'>
                                        <IoSunny style={{
                                            padding: '10px',
                                            height: '50px',
                                            width: '50px',
                                            background: 'white',
                                            borderRadius: '50%',
                                            fontSize: '65px',
                                            cursor: 'pointer',
                                            display: 'flex',
                                            justifyContent: 'center',
                                            alignItems: 'center'
                                        }} />
                                    </div>
                                    :
                                    <div className='light-font' onClick={()=>handleScene(item)}>
                                        <div style={{height: '50px',width: '50px',background:'white', borderRadius:'50%'}}></div>
                                    </div>
                                    }
                                    <div >
                                        <SlOptions className='scenes-option-icon' style={{ padding: '5px', background: 'white', borderRadius: '50%', color: 'black', fontSize: '25px', cursor: 'pointer' }} />
                                        <div className='scenes-option'>
                                            <div style={{ display: 'flex', flexDirection: 'column', gap: '10px', padding: '10px 20px', textAlign: 'start' }}>
                                                <div className='pointer'>Edit</div>
                                                <div className='pointer' style={{ color: 'red' }}>Delete</div>
                                            </div>
                                        </div>
                                    </div>

                                </div>

                                <div style={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
                                    <div style={{ width: '100%', fontSize: '1.2em', fontWeight: 'bold' }}>{item.name.length >= 25 ? `${item.name.slice(0, 25)}...` : `${item.name}`}</div>
                                    <div style={{ fontSize: '0.9em' }}>{[...item?.data?.control?.on, ...item?.data?.control?.off].length} devices</div>
                                </div>
                            </div>)}
                        </div>
                        : <div
                            style={{ textAlign: 'center', alignSelf: 'center', display: 'flex', height: '100%', justifyContent: 'center', alignItems: 'center' }}>
                            <div>
                                <MdAddToPhotos style={{ fontSize: '200px', color: '#723ED8' }} />
                                <div><p style={{ fontSize: '30px', fontWeight: 500 }}>Add Scenes</p></div>
                            </div>
                        </div>
                    : null
                }
            </BackgroundCard>
            <BottomSheet drawer={bottomSheet} setDrawer={setBottomSheet} zIndex={20}>
                <SceneDetail selectedDevices={selectedDevices} data={selectedScene} title={'Update Scene'} setSideDrawer={setSideDrawer} setBottomSheet={setBottomSheet} getScenes={construct} />
            </BottomSheet>
            <SideDrawer drawer={sideDrawer} setDrawer={setSideDrawer} background={'white'} title={"Update Device Settings"} zIndex={22}>
                <SelectDevices setDevices={setSelectedDevices} devices={selectedDevices} />
            </SideDrawer>
        </Main>
    )
}

export default Scenes