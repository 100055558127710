import React, { useEffect, useRef, useState } from 'react'
import ToggleSwitch from './common/ToggleSwitch';
import { RiHeart3Fill, RiEditBoxFill, RiInformationFill, RiClockwise2Fill, RiEditCircleFill, RiAlarmFill, RiTimeFill, RiEdit2Fill } from 'react-icons/ri';
import { AiFillInfoCircle, AiFillSetting } from 'react-icons/ai';
import SideDrawer from './common/SideDrawer';
import Hr from './common/Hr';
import { useUser } from '../Context/context';
import { UserService } from '../services/UserService';
import { User } from '../storage/store';
import { GoClockFill } from "react-icons/go";
import { useToggleTop } from './common/ToggleTop';
import Schedule from '../pages/Home/schedule/Schedule';
import BottomSheet from './common/BottomSheet';
import AddSchedule from '../pages/Home/schedule/AddSchedule';
import Info from './device/Info';
import SwitchSettings from './device/SwitchSettings';
import { FaPen } from "react-icons/fa";

const DetailFrame = ({ children, control }) => {
    const [settingsDrawer, setSettingsDrawer] = useState('');
    const [infoDrawer, setInfoDrawer] = useState('');
    const [scheduleDrawer, setScheduleDrawer] = useState('');
    const [favourite, setFavourite] = useState(false);
    const [scheduleSheet, setScheduleSheet] = useState('');
    const [test, setTest] = useState();

    const Childref = useRef();
    const toggletop = useToggleTop();

    const { device, setDevice, deviceSettings} = useUser();

    async function hearthandle() {
        setFavourite(!favourite);
        const payload = {
            applianceId: device.applianceId,
            favourite: !favourite,
        };
        try {
            const propertyId = User.getPropertyId();
            // console.log("STATE UPDATE...: " + JSON.stringify(payload));
            const response = await UserService.userFav(
                payload,
                propertyId,
            );
        } catch (error) {
            console.warn(error);
            setFavourite(!favourite);
        } finally {
            favAlert();
        }
    }
    useEffect(() => {
        // console.log(device);
        const userName = User.getUser();
        const isfav = device?.usersFavourite ? [...device?.usersFavourite].includes(userName) : false
        setFavourite(isfav);

    }, [device])

    async function favAlert() {

        if (favourite === false) {
            toggletop("Added to favourites", "green");
        } else {
            toggletop("Removed from favourites", "red");
        }
    }
    return (
        <>
            
            <SideDrawer drawer={settingsDrawer} setDrawer={setSettingsDrawer} title={'Setting'}>
                <SwitchSettings/>
            </SideDrawer>

            <SideDrawer drawer={infoDrawer} setDrawer={setInfoDrawer} title={'Info'}>
                <Info/>
            </SideDrawer>

            <SideDrawer drawer={scheduleDrawer} setDrawer={setScheduleDrawer} title={'Schedule'}>
                <Schedule setDrawer={setScheduleSheet} />
            </SideDrawer>

            <BottomSheet drawer={scheduleSheet} setDrawer={setScheduleSheet} title={'Info'} background={'white'}>
                <AddSchedule setDrawer={setScheduleSheet}/>
            </BottomSheet>
            
            <div style={{ backgroundColor: 'white', padding: "25px", borderRadius: "20px", minWidth:'300px' }}>
                    <div style={{ display: 'flex', justifyContent: "space-between" }}>
                        <div>
                            <h1>{device?.name}</h1>
                            <div>{device?.floor} | {device?.room}</div>
                        </div>
                    <div style={{ display: 'flex', gap: '10px' }}>
                            <div><ToggleSwitch id={'wlctoggle1'} checked={device?.props?.state} onChange={()=>{
                                control();
                                device.props.state=!device.props.state;
                                setDevice({...device});}} 
                            /></div>
                    </div>
                    </div>
                    <Hr />
                    <div style={{ display: 'flex', justifyContent: 'start', gap: '10px' }}>
                        {/* heart Icon */}
                        <div>
                            <RiHeart3Fill
                                style={{
                                    color: favourite ? "red" : "grey",
                                    fontSize: 28,
                                    cursor: 'pointer'
                                }}
                                onClick={() => hearthandle()}
                            />
                        </div>

                        {/* Edit Device Icon */}
                        <div>
                            <RiEdit2Fill
                                id='wlcSettingOn'
                                onClick={() => { setSettingsDrawer('open') }}
                                style={{ fontSize: 28, marginRight: "2px", cursor: 'pointer' }}
                            />
                        </div>

                        {/* Info Icon */}
                        <div>
                            <RiInformationFill
                                onClick={() => setInfoDrawer('open')}
                                style={{
                                    fontSize: 28,
                                    cursor: 'pointer'
                                }}
                            />
                        </div>

                        {/* Schedule Icon */}
                        <div className='detail-frame-options'>
                            <RiTimeFill
                                onClick={() => setScheduleDrawer('open')}
                                style={{
                                    fontSize: 28,
                                    cursor: 'pointer'
                                }}
                            />
                        </div>
                    </div>
                <div>
                    {children}
                </div>

            </div>
        </>
    )
}

export default DetailFrame