import React, { useEffect } from "react";
import {
  Card,
  Typography,
  Button,
  message,
  Drawer,
  Input,
  Form,
  Switch,
} from "antd";
import { useLocation } from "react-router-dom";
import { useState } from "react";
import { RiHeart3Fill } from "react-icons/ri";
import { AiFillSetting, AiFillInfoCircle } from "react-icons/ai";
import { UserService } from "../../services/UserService";
import "react-datetime-picker/dist/DateTimePicker.css";
import "react-calendar/dist/Calendar.css";
import "react-clock/dist/Clock.css";
import "../../assets/styles/main.css";
import "../../assets/styles/responsive.css";
import Main from "../layout/Main";
// import ToggleTop from "../common/ToggleTop";
import TimeLineView from "./TimeLineView";
import SwitchInfo from "./SwitchInfo";
import DeviceSpecific from "../../assets/theme/DeviceSpecificColor";
import { IoWaterOutline, IoPowerOutline, IoBulbOutline } from "react-icons/io5";
import { MdOutlet, MdCurtains, MdCurtainsClosed, MdPause } from "react-icons/md";
import ThemeColor from "../../assets/theme/ThemeColor";
import { FaFan } from "react-icons/fa";
import { Position } from "devextreme-react/cjs/autocomplete";
import Fan from "./Fan";
import { User } from "../../storage/store";
import DotSpinner from "../common/DotSpinner";
import CircleBarSpinner from "../common/CircleBarSpinner";
import { useUser } from "../../Context/context";
import DetailFrame from "../DetailFrame";
import Hr from "../common/Hr";
import { useToggleTop } from "../common/ToggleTop";
import SwitchInfo2 from "./SwitchInfo2";
import SwitchSettings from "./SwitchSettings";
import { off } from "devextreme/events";

const SwitchDetail = ({ props }) => {
  const params = useLocation();
  const navigatedDevice = params?.state?.data;
  // const [data, setData] = useState([]);
  const { Title, Text } = Typography;
  const [messageApi, contextHolder] = message.useMessage();
  const [favourite, setFavourite] = useState();
  // const [favmsg, setFavMsg] = useState('none');

  const [inputValue, setInputValue] = useState(params.state.data.name);
  const [inputValue2, setInputValue2] = useState(params.state.data.site);
  const [inputValue3, setInputValue3] = useState(params.state.data.location);
  const [isToggled, setIsToggled] = useState(false);
  const [visible, setVisible] = useState(false);
  const [childrenDrawer, setChildrenDrawer] = useState(false);
  const showDrawer = () => setVisible(true);
  const hideDrawer = () => setVisible(false);
  const [iconColor, setIconColor] = useState();
  const [trigger, setTrigger] = useState(false);
  const [ls, setLs] = useState();
  const [hideToggleButton, setHideToggleButton] = useState(false)

  const { device, setDevice, deviceSettings, setDeviceSettings } = useUser();

  const setToggleTop = useToggleTop();

  // console.log(params)
  // console.log(device)

  const curtain = [
    { id: '1', title: 'Open', icon: <MdCurtains />, borderwidth: 0, },
    { id: '2', title: 'Stop', icon: <MdPause />, borderwidth: 3, },
    { id: '3', title: 'Close', icon: <MdCurtainsClosed />, borderwidth: 0, },
  ];

  const curtainSingleMotor = [
    { id: '1', title: 'Open', icon: <MdCurtains />, borderwidth: 0, },
    { id: '2', title: 'Stop', icon: <MdPause />, borderwidth: 3, },
    { id: '3', title: 'Close', icon: <MdCurtainsClosed />, borderwidth: 0, },
  ];

  const curtainDualMotor = [
    { id: '1', title: 'Open', icon: <MdCurtains />, borderwidth: 0, },
    { id: '2', title: 'Stop', icon: <MdPause />, borderwidth: 3, },
    { id: '3', title: 'Close', icon: <MdCurtainsClosed />, borderwidth: 0, },
  ];

  // console.log(device.deviceType)

  const showChildrenDrawer = () => {
    setChildrenDrawer(true);
  };

  const onChildrenDrawerClose = () => {
    setChildrenDrawer(false);
  };
  const [form] = Form.useForm();
  const handleChange = (event) => {
    setInputValue(event.target.value);
  };
  const handleChange2 = (event) => {
    setInputValue2(event.target.value);
  };
  const handleChange3 = (event) => {
    setInputValue3(event.target.value);
  };
  const toggleButton = () => {
    setIsToggled(!isToggled);
  };

  useEffect(() => {
    setDevice(null);
    construct();
  }, [trigger]);

  async function construct() {
    try {
      // console.log(device);
      const propertyId = User.getPropertyId();
      const deviceId = navigatedDevice.deviceId;

      // console.log(deviceId);

      const result = await UserService.getConfig(propertyId);
      // console.log(result.devices);
      const filt = [...result.devices].filter(item => item.applianceId == params.state.data.applianceId);
      // console.log(filt[0]);
      let _device = filt[0] ?? navigatedDevice;
      // getUserConfig();
      console.warn(result);
      // console.log(_device);

      const ls = await UserService.getLastStatebydeviceId(propertyId, deviceId);
      // console.log(ls['devices'][_device?.applianceId]);
      // console.log(ls);
      setLs(ls['devices'][_device?.applianceId]);
      _device.props.speed = ls['devices'][_device.applianceId]['speed'] ?? null;
      _device.props.state = ls['devices'][_device.applianceId]['state'];
      _device.props.timeStamp = ls['devices'][_device.applianceId]['timeStamp'] ?? null;
      setDevice({ ..._device, build: ls.build, firmwareVersion: ls.firmwareVersion });
      setDeviceSettings(<SwitchSettings />);
    } catch (e) {
      console.log(e);
    }
  }

  async function handleDevice() {

    let payload;
    if (!device.props.state) {
      if (device.supportedDeviceTraits?.includes('101')) {
        payload = {
          on: [`${device.applianceId}`],
          off: [],
          action: {
            [device.applianceId]: {
              id: Number(device.pin),
              trait: 101,
              value: !Number(device.props.state),
            },
          },
        };
      } else {
        if (device.deviceType.toLowerCase() == 'fan') {
          if (device.meta.model === 'W1F6SM_1') {
            payload = {
              on: [`${device.applianceId}`],
              off: [],
              action: {
                [device.applianceId]: {
                  id: Number(device.pin),
                  trait: 201,
                  value: Number(device?.props?.speed) ?? 3,
                }
              }
            };
          } else {
            payload = {
              on: [`${device.applianceId}`],
              off: [],
              speeds: {
                [device.applianceId]: Number(device?.props?.speed) ?? 6,
              },
            };
          }
          
        } else {
          payload = {
            on: [`${device.applianceId}`],
            off: [],
            switch: {
              [device.applianceId]: {
                id: Number(device.pin),
                status: !Number(device.props.state),
              },
            },
          };
        }
      }
    } else {
      if (device.supportedDeviceTraits?.includes('101')) {
        payload = {
          on: [`${device.applianceId}`],
          off: [],
          action: {
            [device.applianceId]: {
              id: Number(device.pin),
              trait: 101,
              value: !Number(device.props.state),
            },
          },
        };
      } else {
        
        if (device.deviceType.toLowerCase() == 'fan') {
          if (device.meta.model === 'W1F6SM_1') {
            payload = {
              on: [`${device.applianceId}`],
              off: [],
              action: {
                [device.applianceId]: {
                  id: Number(device.pin),
                  trait: 201,
                  value: Number(0),
                }
              }
            };
          } else {
            payload = {
              on: [`${device.applianceId}`],
              off: [],
              speeds: {
                [device.applianceId]: 0,
              },
            };
          }
          
        }
      }
    }
    // console.log(payload);

    
    try {
      const PropertyId = User.getPropertyId();
      const response = await UserService.stateUpdate(payload, PropertyId);
      console.warn(response);
      // setTrigger(!trigger);
    } catch (error) {
      console.warn(error);
    }
  }

  const controlDevice = () => {
    let payload1;
    if (device.props.state) {
      payload1 = {
        on: [],
        off: [device.applianceId]
      }
    } else {
      payload1 = {
        on: [device.applianceId],
        off: []
      }
    }
    // console.log(payload1);
    try {
      const properteyId = User.getPropertyId();
      const response = UserService.stateUpdate(payload1, properteyId);
      setToggleTop('Success', 'green');
      setTrigger(!trigger);
    } catch (e) {
      setToggleTop('Failed', 'red');
    }
  }

  //save button inside settings.
  async function handleClick() {
    const storedValue = inputValue;
    const storedValue1 = inputValue2;
    const storedValue2 = inputValue3;

    const payload = {
      ...device,
      name: storedValue,
      site: storedValue2,
      location: storedValue1,
    };
    try {
      const response = await UserService.updateDevice(
        payload,
        params.state.propertyId
      );
      messageApi.open({
        type: "success",
        content: "Saved successfully",
        duration: 10,
      });
      console.warn(response);
    } catch (error) {
      console.warn(error);
      messageApi.open({
        type: "error",
        content: "Something Went Wrong",
        duration: 10,
      });
    }
  }

  async function hearthandle() {
    setFavourite(!favourite);
    favAlert();
    const payload = {
      applianceId: params.state.data.applianceId,
      favourite: !favourite,
    };
    try {
      const response = await UserService.fav(
        payload,
        params.state.propertyId,
      );
      const data = await JSON.stringify(response)
      // console.log(data);
      const result = await UserService.getConfig(params.state.propertyId,);
      // console.log(result.devices);
      const filt = [...result.devices].filter(item => item.applianceId == params.state.data.applianceId);
      setFavourite(filt[0].favourite)
      // console.log(filt[0].favourite);
      // getUserConfig();
      console.warn(response);
    } catch (error) {
      console.warn(error);
      setFavourite(!favourite);
    }
  }

  async function favAlert() {
    if (favourite === false) {
      setToggleTop("Your device has been successfully added to your favorites.", 'green');
    } else {
      setToggleTop("Your device has been successfully removed from your favorites.", 'red');
    }
  }

  const curtainManagement = (item) => (
    <div key={item.id} style={{ ...styles.curtainButton, borderLeftWidth: `${item.borderwidth}px`, borderRightWidth: `${item.borderwidth}px`, }}>
      {item.icon}
    </div>
  );

  const curtainTitleManagement = (item) => (
    <div key={item.id} style={{ fontSize: 18, width: '33%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
      {item.title}
    </div>
  );
  // console.log(device);
  // console.log(ls);
  return (
    <>

      <Main >
        {device ?
          <DetailFrame setTrigger={setTrigger} trigger={trigger} control={handleDevice} toggleButton={false}>
            <div className="switchdetails-main-card">
              <div style={{ width: '60%', height: '100%', margin: '0 auto', justifyContent: 'center', alignSelf: 'center', }}>
                <div style={{ fontSize: "200px", textAlign: 'center' }} >
                  {device ? (
                    device.deviceType === "SWITCH" ? (
                      <IoPowerOutline style={{ color: iconColor }} />
                    ) : device.deviceType === "LIGHT" ? (
                      <IoBulbOutline style={{ color: iconColor }} />
                    ) : device.deviceType === "FAN" ? (
                      <Fan device={device} handleDevice={handleDevice} />
                    ) : device.deviceType === "SOCKET" ? (
                      <MdOutlet style={{ color: iconColor }} />
                    ) : (
                      <MdOutlet style={{ color: iconColor }} />
                    )
                  ) : null}
                </div>
              </div>
              <div className="switchdetails-main-card-vert-hortizon-line" ></div>
              <div className="switchdetails-main-card-TimeLineView-parent">
                <TimeLineView
                  applianceId={params.state.data.applianceId}
                />
              </div>
            </div>
          </DetailFrame>

          : <div
            className="switchdetails-layout-content"
            style={{

              height: '80lvh',
            }}>
            <CircleBarSpinner />
          </div>
        }
        {
          childrenDrawer ?
            <SwitchInfo
              isOpen={childrenDrawer}
              isClose={() => onChildrenDrawerClose()}
            />
            : null}
      </Main >
    </>
  );
};
export default SwitchDetail;

const styles = {
  curtainButton: {
    fontSize: 40,
    padding: 15,
    paddingRight: 35,
    paddingLeft: 35,
    borderLeftStyle: 'solid',
    borderLeftColor: 'white',
    display: 'flex',
    alignItems: 'center',
    borderRightStyle: 'solid',
    borderRightColor: 'white',
    width: '33%',
    justifyContent: 'center'
  },
  curtainContainer: {
    display: 'flex',
    borderRadius: 50,
    width: '65%',
    justifyContent: 'center',
    margin: '0 auto',
    marginBottom: '20px'
  }
}