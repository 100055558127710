import React, { useEffect, useState } from 'react'
import Main from '../../../components/layout/Main'
import { UserService } from '../../../services/UserService'
import { User } from '../../../storage/store';
import CircleBarSpinner from '../../../components/common/CircleBarSpinner';
import { MdOutlineTouchApp } from "react-icons/md";
import { SlOptions } from "react-icons/sl";
import { MdAddToPhotos } from "react-icons/md";
import { IoSunny } from "react-icons/io5";
import BackgroundCard from '../../../components/layout/BackgroundCard';
import { FaFire } from 'react-icons/fa';
import DoubleTapIcon from '../../../components/icons/DoubleTapIcon';
import ThemeColor from '../../../assets/theme/ThemeColor';
import BottomSheet from '../../../components/common/BottomSheet';
import SceneDetail from './SceneDetail';
import SideDrawer from '../../../components/common/SideDrawer';
import SelectDevices from '../../../components/common/SelectDevices';
import { useToggleTop } from '../../../components/common/ToggleTop';
import { useUser } from '../../../Context/context';
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min';
import { IoIosAdd } from "react-icons/io";

const Scenes = () => {

    const [scenes, setScenes] = useState(null);
    const [bottomSheet, setBottomSheet] = useState('');
    const [sideDrawer, setSideDrawer] = useState('');
    const [selectedDevices, setSelectedDevices] = useState({ on: [], off: [] });
    const [selectedScene, setSelectedScene] = useState();
    const [sceneTitle, setSceneTitle] = useState('add');

    const PropertyId = User.getPropertyId();

    // const location = useLocation();

    const { allDevices, setAllDevices } = useUser();
    // const { devices } = location.state

    const setToggleTop = useToggleTop();

    const construct = async () => {
        const response = await UserService.getScenes(PropertyId);
        setScenes([...response]);
    }
    const _allDevices=async()=>{
            (async () => {
              if(PropertyId===null || PropertyId === undefined){
                // User.setPropertyId();
                PropertyId = User.getPropertyId();
              }
              const config = await UserService.getConfig(
                PropertyId
              )
              const configDev = config.devices;
              // console.log(config.devices)
              const lastStates = await UserService.getLastStateByProperty(
                PropertyId,
              )
        
              if (lastStates) {
                let allRoomDevices = configDev;
                allRoomDevices.forEach((data, index) => {
                  if (
                    lastStates[0]?.devices[data.applianceId] &&
                    "speed" in lastStates[0].devices[data.applianceId]
                  ) {
                    data.props = {
                      state: lastStates[0].devices[data.applianceId]
                        ? lastStates[0].devices[data.applianceId].state
                        : false,
                      speed: lastStates[0].devices[data.applianceId]
                        ? lastStates[0].devices[data.applianceId].speed
                        : 3,
                      timeStamp: lastStates[0].devices[data.applianceId]
                        ? lastStates[0].devices[data.applianceId].timeStamp
                        : null,
                    };
                  } else if (
                    lastStates[0]?.devices[data.applianceId] &&
                    "value" in lastStates[0].devices[data.applianceId]
                  ) {
                    data.props = {
                      state: lastStates[0]?.devices[data.applianceId]
                        ? lastStates[0]?.devices[data.applianceId].state
                        : false,
                      value: lastStates[0]?.devices[data.applianceId]
                        ? lastStates[0]?.devices[data.applianceId].value
                        : "",
                      timeStamp: lastStates[0]?.devices[data.applianceId]
                        ? lastStates[0]?.devices[data.applianceId]?.timeStamp
                        : null,
                      ...lastStates[0]?.devices[data.applianceId],
                    };
                  } else {
                    data.props = {
                      state: lastStates[0]?.devices[data.applianceId]
                        ? lastStates[0]?.devices[data.applianceId].state
                        : false,
                      timeStamp: lastStates[0]?.devices[data.applianceId]
                        ? lastStates[0]?.devices[data.applianceId]?.timeStamp
                        : null,
                      ...lastStates[0]?.devices[data.applianceId],
                    };
                  }
                });
                setAllDevices(configDev);
                
              }
            })()
        
            //Runs only on the first render
        
    }

    useEffect(() => {
        construct();
        _allDevices()
    }, [PropertyId]);

    const handleSceneEdit = (scene) => {
        setSceneTitle('update');
        setSelectedScene({ ...scene });
        setBottomSheet('open');
    }
    const handleSceneAdd = (sceneType) => {
        setSceneTitle('add');
        setSelectedScene({sceneType});
        setBottomSheet('open');
    }
    const handleSceneDelete=async(scene)=>{
        if(window.confirm(`Sure DELETE ${scene?.name}`)){
            try{
                const sceneDelete = await UserService.deleteScene(PropertyId, scene._id);
                setToggleTop(`Scene "${scene.name}" deleted successfully`, 'green');
                construct();
            }catch(e){
                setToggleTop(`Error Deleting Scene "${scene.name}"`);
            }
            
        }
    }
    const handleScene = async (data) => {
        if (Object.keys(data).includes('_id')) {
            const val = window.confirm(`Sure start ${data.name} scene?`);
            if (val) {
                // const response = UserService.triggerScene({ sceneId: data?._id });
                setToggleTop(`Scene '${data.name}' started.`, "green");
            }
            else
                setToggleTop("Scene not Triggered.", "red");
        } else console.log('not a scene');
    }
    // console.log('allDevices', allDevices)
    return (
        <Main>
            <BackgroundCard background={'white'}>
                <div style={{ display: 'flex', justifyContent: 'end', position: 'relative' }}>
                    <IoIosAdd className='scenes-addScenes' style={{ fontSize: '40px', boxShadow: '1px 1px 10px -2px grey', borderRadius: '50%', cursor: 'pointer' }} />
                    <div className='scenes-sceneTypes' style={{ position: 'absolute', background: 'transparent', right: '20px', top: '20px', zIndex: 1 }}>
                        <div style={{ display: 'flex', flexDirection: 'column', gap: '5px' }}>
                            <div className='cursor-pointer' style={addScene_types_style} onClick={()=>handleSceneAdd('tapToStart')}>
                                <DoubleTapIcon style={{
                                    cursor: 'pointer',
                                    color: ThemeColor.pirPrimary
                                }} />
                                <div>
                                    <div><strong style={{ fontSize: '1.2em' }}>Tap-To-Start</strong></div>
                                    <div><small>Ex: Turn ON all lights in hall with one tap.</small></div>
                                </div>
                            </div>
                            <div className='cursor-pointer' style={addScene_types_style} onClick={()=>handleSceneAdd('sunRiseSunSet')}>
                                <IoSunny style={{
                                    cursor: 'pointer',
                                    color: ThemeColor.pirPrimary,
                                    fontSize: '40px'
                                }} />
                                <div>
                                    <div><strong style={{ fontSize: '1.2em' }}>Sunrise/Sunset</strong></div>
                                    <p style={{fontSize:'0.8em'}}>Ex: When the Sun rises, lights automatically goes off.</p>
                                </div>
                            </div>
                        </div>


                    </div>
                </div>
                {scenes ?
                    scenes.length ?
                        <div
                            // className='grid-container-sc'
                            style={{
                                display: 'flex',
                                flexWrap: 'wrap',
                                gap: '20px',
                                justifyContent: 'start'
                            }}
                        >

                            {[...scenes]?.map((scene, index) => <div key={'scene'+index}
                                style={{
                                    backgroundColor: ThemeColor.quickActions[index % ThemeColor.quickActions.length],
                                    padding: '15px 20px',
                                    borderRadius: '8px',
                                    minWidth: '200px',
                                    maxWidth: '200px',
                                    height: '180px',
                                    display: 'flex',
                                    flexDirection: 'column',
                                    justifyContent: 'space-between',
                                    gap: '20px',
                                }}
                            >
                                <div style={{ display: 'flex', justifyContent: 'space-between', position: 'relative' }}>
                                    {scene.sceneType === "tapToStart" ? <div className='light-font ' onClick={() => handleScene(scene)}>
                                        <DoubleTapIcon style={{
                                            padding: '10px',
                                            height: '50px',
                                            width: '50px',
                                            background: 'white',
                                            borderRadius: '50%',
                                            fontSize: '65px',
                                            cursor: 'pointer',
                                            display: 'flex',
                                            justifyContent: 'center',
                                            alignItems: 'center'
                                        }} />
                                    </div>
                                        :
                                        scene.sceneType === "sunRiseSunSet" ?
                                            <div className='light-font'>
                                                <IoSunny style={{
                                                    padding: '10px',
                                                    height: '50px',
                                                    width: '50px',
                                                    background: 'white',
                                                    borderRadius: '50%',
                                                    fontSize: '65px',
                                                    cursor: 'pointer',
                                                    display: 'flex',
                                                    justifyContent: 'center',
                                                    alignItems: 'center'
                                                }} />
                                            </div>
                                            :
                                            <div className='light-font' onClick={() => handleScene(scene)}>
                                                <div style={{ height: '50px', width: '50px', background: 'white', borderRadius: '50%' }}></div>
                                            </div>
                                    }
                                    <div >
                                        <SlOptions className='scenes-option-icon' style={{ padding: '5px', background: 'white', borderRadius: '50%', color: 'black', fontSize: '25px', cursor: 'pointer' }} />
                                        <div className='scenes-option'>
                                            <div style={{ display: 'flex', flexDirection: 'column', gap: '10px', padding: '10px 20px', textAlign: 'start' }}>
                                                <div className='pointer' onClick={() => handleSceneEdit(scene)}>Edit</div>
                                                <div className='pointer' style={{ color: 'red' }} onClick={()=>handleSceneDelete(scene)}>Delete</div>
                                            </div>
                                        </div>
                                    </div>

                                </div>

                                <div style={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
                                    <div style={{ width: '100%', fontSize: '1.2em', fontWeight: 'bold' }}>{scene.name.length >= 25 ? `${scene.name.slice(0, 25)}...` : `${scene.name}`}</div>
                                    <div style={{ fontSize: '0.9em' }}>{[...scene?.data?.control?.on, ...scene?.data?.control?.off].length} devices</div>
                                </div>
                            </div>)}
                        </div>
                        : <div
                            style={{ textAlign: 'center', alignSelf: 'center', display: 'flex', height: '100%', justifyContent: 'center', alignItems: 'center' }}>
                            <div>
                                <MdAddToPhotos style={{ fontSize: '200px', color: '#723ED8' }} />
                                <div><p style={{ fontSize: '30px', fontWeight: 500 }}>Add Scenes</p></div>
                            </div>
                        </div>
                    : null
                }
            </BackgroundCard>
            <BottomSheet drawer={bottomSheet} setDrawer={setBottomSheet} zIndex={20} background={'#ECF1FD'}>
                <SceneDetail selectedDevices={selectedDevices} data={selectedScene} title={sceneTitle} setSideDrawer={setSideDrawer} setBottomSheet={setBottomSheet} getScenes={construct} />
            </BottomSheet>
            {/* <SideDrawer drawer={sideDrawer} setDrawer={setSideDrawer} background={'white'} title={"Update Device Settings"} zIndex={22}>
                <SelectDevices setDevices={setSelectedDevices} devices={selectedDevices} />
            </SideDrawer> */}
        </Main>
    )
}

const addScene_types_style = {
    display: 'flex', 
    justifyContent: 'start', 
    alignItems: 'center', 
    padding: '10px 20px', 
    gap: '10px', 
    boxShadow: '1px 1px 10px -2px grey', 
    background: 'white',
    width:'300px'
}

export default Scenes