import React, {
  createContext,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { UserService } from "../services/UserService";
import { User } from "../storage/store";
import { Color } from "devextreme-react/cjs/chart";

const UserContext = createContext();

export const UserProvider = ({ children }) => {
  const [profileName, setProfileName] = useState([]);
  const [configMain, setConfigMain] = useState();
  const [selectedItem, setSelectedItem] = useState(0);
  const [PropertyId_c, setPropertyId_c] = useState(null);
  const [floor, setFloor] = useState(null);
  const [selectedHouse, setSelectedHouse] = useState(null);
  const [selectedConfig, setSelectedConfig] = useState(null);
  const [allDevices, setAllDevices] = useState([]);
  const [offlineConfig, setOfflineConfig] = useState(null);
  const [routineDevices, setRoutineDevices] = useState({
    on:[], 
    off:[],
    name:'',
    time:'',
    weekDays:[]
  });
  const [updateRoutine, setUpdateRoutine] = useState({
    on:[], 
    off:[],
    name:'',
    time:'',
    weekDays:[]
  });
  const [scheduleG, setScheduleG] = useState(null);
  const [device, setDevice] = useState();
  const [toggleTop, setToggleTop] = useState({
    color: 'blue', message:'Alert !', toggle:false
  });
  const [deviceSettings, setDeviceSettings] = useState();
  const [activePayload, setActivePayload] = useState();
  

  //This Functions goes to selectedecosystem to get propertey
  async function setPropertyIdandGetProperty(){
    const result = await UserService.getProperty();
      if (result) {
        let propertyId = User.getPropertyId();
        if(propertyId===null || propertyId===undefined){
          propertyId= result[0].property.id
          User.setPropertyId(propertyId);
          setPropertyId_c(propertyId);
        }
        propertyId = User.getPropertyId();
        return result;
    }
  }

  async function getProperty() {
    try {
        const result = setPropertyIdandGetProperty();
        const username = await UserService.getUserName();
        User.setUser(username);
    } catch (error) {
      console.log("error...", error);
    }
  }

  //This Function has get the data of selected property
  const selectedPropertey = async () => {
    try {
      let res = await UserService.getProperty();
      
      if (res && res.length > 0) {

        let propertyId = User.getPropertyId();
        if(propertyId===null || propertyId===undefined, propertyId==="undefined", propertyId==="null"){

          propertyId= res[0].property.id
          User.setPropertyId(propertyId);
          setPropertyId_c(propertyId);
        }
        propertyId = User.getPropertyId();
        let selected = res.filter(item=>item.property.id===propertyId);
        let other = res.filter(item=>item.property.id!==propertyId);

        res = [...selected, ...other];

        const newName = res.map((item) => ({
          id: item.property.id,
          name: item.property.name,
        }));
        

        setProfileName(newName);
      }
    } catch (error) {
      console.log("Error fetching data:", error);
    }
  };

  //This function can handle the selected button in the header to get the selected item
  const handleEcoSystemSelection = useMemo(
    () => (e) => {
      e.preventDefault();
      const selectedSystem = e.target.value;
      User.setPropertyId(selectedSystem);
      setPropertyId_c(selectedSystem);

      getRooms();
    },
    []
  );

  //This function has give the rooms and floors in the selected propertey
  async function getRooms() {
    try {
      const propertyId = User.getPropertyId();
      const result = await UserService.getRooms(propertyId);
      if (result.floors) {
        setFloor(
          result.floors.map((el, index) => {
            return {
              floorId: el._id,
              label: el.name,
              rooms: el.rooms,
              index
            };
          })
        );
      }
    } catch (error) {
      console.log("error...", error);
    }
  }

  useEffect(() => {
    const pid = User.getPropertyId();
    getProperty();
    if (selectedItem !== null) {
    }
  }, [selectedItem]);

  // This function gets a data from door sensor for day
  return (
    <UserContext.Provider
      value={{
        profileName,
        selectedItem,
        floor,
        setFloor,
        getProperty,
        setSelectedItem,
        selectedPropertey,
        handleEcoSystemSelection,
        getRooms,
        selectedHouse,
        setSelectedHouse,
        selectedConfig,
        setSelectedConfig,
        allDevices,
        setAllDevices,
        setOfflineConfig,
        routineDevices, setRoutineDevices,
        updateRoutine, setUpdateRoutine,
        device, setDevice,
        toggleTop, setToggleTop,
        deviceSettings, setDeviceSettings,
        scheduleG, setScheduleG,
        activePayload, setActivePayload,
        PropertyId_c, setPropertyId_c
      }}
    >
      {children}
    </UserContext.Provider>
  );
};

export const useUser = () => {
  return useContext(UserContext);
};
