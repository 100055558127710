import React, { useEffect, useState } from 'react'
import { AiFillInfoCircle, AiFillSetting } from 'react-icons/ai';
import { RiHeart3Fill } from 'react-icons/ri';
import { User } from '../../../storage/store';
import { UserService } from '../../../services/UserService';
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min';
import TimeLineView from '../TimeLineView';
import CircleBarSpinner from '../../common/CircleBarSpinner';
import W1CM_1 from './W1CM_1';
import W2CM_1 from './W2CM_1';
import TimeLineViewTwoDevice from '../TimeLineViewTwoDevice';
import WCMsettings from './WCMsettings';
import Info from '../Info';
import SideDrawer from '../../common/SideDrawer';
import GateCloseIcon from '../../icons/GateCloseIcon';
import GateOpenIcon from '../../icons/GateOpenIcon';
import GateButtonCloseLightIcon from '../../icons/GateButtonCloseLightIcon';
import GateButtonOpenLightIcon from '../../icons/GateButtonOpenLightIcon';
import GateButtonCloseDarkIcon from '../../icons/GateButtonCloseDarkIcon';
import GateButtonOpenDarkIcon from '../../icons/GateButtonOpenDarkIcon';
import DetailFrame from '../../DetailFrame';
import { useUser } from '../../../Context/context';


const WCM_1 = ({ props, setFavMsg }) => {

  const params = useLocation();
  const navigatedDevice = params?.state?.data;

  const {device, setDevice} = useUser();
  const [ls, setLs] = useState();

  // console.log(device ?? 'no config');
  // console.log(ls ?? 'no ls')
  // console.log(props);

  return (
    <>
      <div style={{ display: 'flex', justifyContent: 'space-evenly', padding: '0px 30px 0px 0px' }}>
        {device.meta.model === "W2CM_1" ? <W2CM_1 /> : <W1CM_1 />}
              <div className='wlc_timeline_view_container' >
                <TimeLineView applianceId={device?.applianceId} />
                
              </div>
        </div>
      
    </>
  )
}

export default WCM_1