import { Background } from 'devextreme-react/cjs/range-selector'
import React, { useEffect, useState } from 'react'
import ThemeColor from '../../../assets/theme/ThemeColor'
import Hr from '../../../components/common/Hr'
import SideDrawer from '../../../components/common/SideDrawer'
import SelectDevices from '../../../components/common/SelectDevices'
import { UserService } from '../../../services/UserService'
import { User } from '../../../storage/store'
import { useToggleTop } from '../../../components/common/ToggleTop'

const SceneDetail = ({ data, title, setSideDrawer, selectedDevices, setBottomSheet, getScenes }) => {

    const [payload, setPayload] = useState({
        name: data.name,
        data: {
            control: {
                on: [...data?.data?.control?.on],
                off: [...data?.data?.control?.off]
            }
        }
    });
    const setToggleTop = useToggleTop();
    const deviceLength = [...selectedDevices?.on, ...selectedDevices?.off].length;

    useEffect(() => {
        payload.data.control.on = [...selectedDevices.on];
        payload.data.control.off = [...selectedDevices.off];
        setPayload({ ...payload });
        console.log(data);
    }, [selectedDevices]);

    const handleSceneUpdate = async () => {
        try {
            const propertyId = User.getPropertyId();
            const response = await UserService.updateScene(payload, propertyId, data._id);
            console.log(response);
            getScenes();
            setBottomSheet('close');
            setToggleTop('Scene updated successfully.', 'green');

        }catch(e){
            setToggleTop('Problem updating scenes !', 'red');
        }
    }
    console.log(payload);
    return (
        <>
            <div style={{ padding: '20px' }}>
                <div style={{
                    display: 'flex',
                    justifyContent: 'space-between'
                }}>
                    <div style={{ cursor: 'pointer' }} onClick={handleSceneUpdate}>Update</div>
                    <div><p style={{ fontSize: '1.5em' }}>{title}</p></div>
                    <div style={{ cursor: 'pointer' }} onClick={() => {setBottomSheet('close');getScenes()}}>Cancel</div>
                </div>
                <Hr />

                <div style={{ display: 'flex', flexDirection: 'column', gap: '50px' }}>
                    <div style={{
                        display: 'flex',
                        flexDirection: 'column',
                        gap: '10px'
                    }}>
                        <div style={{ fontSize: '1.2em' }}>Name</div>
                        <div><input disabled={title === 'Update Scene' ? true : false} style={inputStyleUpdate} defaultValue={data?.name ?? 'nn'} /></div>
                        <sub style={{ color: ThemeColor.pirPrimary }}>Name can't be edited once created.</sub>
                    </div>

                    <div style={{
                        display: 'flex',
                        flexDirection: 'column',
                        gap: '10px'
                    }}>
                        <div style={{ fontSize: '1.2em' }}>Control Devices</div>
                        <p style={{ color: 'grey' }}>{deviceLength}  {deviceLength > 1 ? 'Devices' : 'Device'} Changed</p>

                    </div>

                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', gap: '20px' }}>
                        <div
                            style={{
                                backgroundColor: ThemeColor.pirPrimary,
                                color: 'white',
                                padding: '10px',
                                borderRadius: '8px',
                                cursor: 'pointer'
                            }}
                            onClick={() => setSideDrawer('open')}
                        >Add Change</div>
                    </div>
                </div>
            </div>


        </>
    )
}
const inputStyleAdd = {
    width: '100%',
    border: 'none',
    borderBottom: '1px solid black',
    color: 'black',
    outline: 'none'
}
const inputStyleUpdate = {
    width: '100%',
    border: 'none',
    borderBottom: '1px solid black',
    color: 'grey',
    outline: 'none'
}
export default SceneDetail