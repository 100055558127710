import React from 'react'

const DoubleTapIcon = ({style}) => {
    return (
        <div style={style}>
            <svg width="30" height="30" viewBox="0 0 14 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M5 6C5 5.73478 5.10536 5.48043 5.29289 5.29289C5.48043 5.10536 5.73478 5 6 5C6.26522 5 6.51957 5.10536 6.70711 5.29289C6.89464 5.48043 7 5.73478 7 6V10.47L8.21 10.6L13.15 12.79C13.68 13.03 14 13.56 14 14.14V18.5C13.97 19.32 13.32 19.97 12.5 20H6C5.62 20 5.26 19.85 5 19.57L0.0999999 15.37L0.84 14.6C1.03 14.39 1.3 14.28 1.58 14.28H1.8L5 16V6ZM6 2C7.06087 2 8.07828 2.42143 8.82843 3.17157C9.57857 3.92172 10 4.93913 10 6C10 7.5 9.2 8.77 8 9.46V8.24C8.61 7.69 9 6.89 9 6C9 5.20435 8.68393 4.44129 8.12132 3.87868C7.55871 3.31607 6.79565 3 6 3C5.20435 3 4.44129 3.31607 3.87868 3.87868C3.31607 4.44129 3 5.20435 3 6C3 6.89 3.39 7.69 4 8.24V9.46C2.8 8.77 2 7.5 2 6C2 4.93913 2.42143 3.92172 3.17157 3.17157C3.92172 2.42143 4.93913 2 6 2ZM6 0C7.5913 0 9.11742 0.632141 10.2426 1.75736C11.3679 2.88258 12 4.4087 12 6C12 7.7 11.29 9.23 10.16 10.33L9.16 9.88C10.28 8.96 11 7.56 11 6C11 4.67392 10.4732 3.40215 9.53553 2.46447C8.59785 1.52678 7.32608 1 6 1C4.67392 1 3.40215 1.52678 2.46447 2.46447C1.52678 3.40215 1 4.67392 1 6C1 8.05 2.23 9.81 4 10.58V11.66C1.67 10.83 0 8.61 0 6C0 4.4087 0.632141 2.88258 1.75736 1.75736C2.88258 0.632141 4.4087 0 6 0Z" fill={'color' in style ? style.color : "black"} />
        </svg>
        </div>

    )
}

export default DoubleTapIcon