import React, { useEffect, useState } from 'react'
import { SlReload } from 'react-icons/sl';
import ThemeColor from '../../assets/theme/ThemeColor';
import { FcDeleteDatabase } from "react-icons/fc";

const CircleBarSpinner = () => {
  const [time, setTime ] = useState();
  useEffect(()=>{
    setTime(true);
    const timer = setTimeout(()=>setTime(false), 7000);
    
    return () => clearTimeout(timer);
  }, [])
  return (
   <>
    {time ? <div style={{
        width:'100%',
        height:'100%',
        display:'flex',
        justifyContent:'center',
        alignItems:'center'
    }}>
        <div className="circle-bar-spinner">
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
        </div>
    </div> : 
    <div style={{height:'100%', display:'flex', justifyContent:'center', alignItems:'center', flexDirection:'column'}}>
      <div><FcDeleteDatabase className='rotate' style={{fontSize:'240px', color:ThemeColor.pirPrimary}}/></div>
      <div style={{fontSize:'30px'}}>No data !</div>
    </div>}
   </>
  )
}

export default CircleBarSpinner